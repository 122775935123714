.mainWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
  max-width: 1392px;
}

.cardInfo {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--brand-color-secondary-blue, #1890ff);
  background: linear-gradient(
    90deg,
    rgba(94, 176, 224, 0.8) 0%,
    rgba(55, 116, 182, 0.8) 100%
  );
}

.textInfo {
  color: var(--neutral-50, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.wrapperFields {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.wrapperAction {
  display: flex;
  align-items: center;
  gap: 16px;
}

.buttonAction {
  width: 240px;
  height: 40px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
}
