.wrapperField {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.labelField {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.labelError {
  color: var(--other-danger, #ea1a1a);
  font-size: 14px;
  margin: 0;
}
